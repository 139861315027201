import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby";
import { FaCheck } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

const Cennik = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/las1.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
      }
      mobileImage: file(relativePath: { eq: "images/photos/las1-mobile.jpg" }) {
        childImageSharp {
            fluid(quality: 50){
            ...GatsbyImageSharpFluid
            }
        }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout location={props.location}>
      <Fade up>
        <section id="section6">
          <div className="content-container prices">
          <h1>PRICES</h1>

            <div style={{ marginBottom: 50 }}>
              <table style={{ marginBottom: 50 }}>
                <tr>
                  <th></th>
                  <th>Amber 1</th>
                  <th>Amber 2</th>
                  <th>Amber 3</th>
                </tr>
                <tr>
                  <td><b>OFF-SEASON</b></td>
                </tr>
                <tr>
                  <td>1.10 - 30.05</td>
                  <td>200 zł - 220 zł</td>
                  <td>180 zł - 200 zł</td>
                  <td>250 zł - 300 zł</td>
                </tr>
                <tr>
                  <td>1-30.06 i 1-30.09</td>
                  <td>250 zł - 300 zł</td>
                  <td>250 zł - 280 zł</td>
                  <td>350 zł - 450 zł</td>
                </tr>
                <tr>
                  <td><b>HIGH SEASON</b></td>
                </tr>
                <tr>
                  <td>1.07-30.08</td>
                  <td>400 zł - 500 zł</td>
                  <td>350 zł - 450 zł</td>
                  <td>600 zł - 800 zł</td>
                </tr>
                <tr>
                  <td>May holidays, Easter, Christmas, New Year's Eve</td>
                  <td>280 zł - 450 zł</td>
                  <td>250 zł - 400 zł</td>
                  <td>350 zł - 600 zł</td>
                </tr>
              </table>

            </div>

            <span className='divider' />

            <h2>Additional information and booking conditions:</h2>
            <ul style={{ marginBottom: 50 }}>
              <li><FaCheck />Check-in from 16.00; Check-out till 10.00</li>
              <li><FaCheck />The obligatory one-time service fee:
                <ul>
                  <li><FaCheck />Amber 1 and Amber 2 – 200 zł</li>
                  <li><FaCheck />Amber 3 - 250 zł</li>
                </ul>
              </li>

              <li><FaCheck />The obligatory climatic fee - 3 PLN / person / day</li>
              <li><FaCheck />At check-in, we collect a cash refundable deposit of:
                <ul>
                  <li><FaCheck />Amber 1 and Amber 2 – 300 zł</li>
                  <li><FaCheck />Amber 3 - 500 zł</li>
                </ul>
              </li>
              <li><FaCheck />30% advance payment is required to make a reservation.
                <ul>
                  <li><FaCheck />Bank account:  45102039580000970202595452 Bank PKO BP SA</li>
                </ul>
              </li>
              <li><FaCheck />The remaining value of the payment for the stay payable in cash at the time of check-in or by bank transfer to the above-mentioned account number the day before arrival.</li>
              <li><FaCheck />In high season, stay for a minimum of 7 days.</li>
              <li><FaCheck />In off season, stay for a minimum of 2 days.</li>
            </ul>

            <span className='divider' />
          </div>
        </section>
      </Fade>
    </Layout>
  )
}

export default Cennik
